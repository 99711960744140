export const getStartOfWeek = (activeStart) => {
    const startOfWeek = new Date(activeStart);
    startOfWeek.setHours(0, 0, 0, 0); // Set to start of the day

    const isoString = `${startOfWeek.getFullYear()}-${(startOfWeek.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${startOfWeek.getDate().toString().padStart(2, '0')}T00:00:00.000Z`;

    return isoString;
};

export const getEndOfWeek = (activeEnd) => {
    const endOfWeek = new Date(activeEnd);
    endOfWeek.setHours(23, 59, 59, 999); // Set to end of the day

    const isoString = `${endOfWeek.getFullYear()}-${(endOfWeek.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${endOfWeek.getDate().toString().padStart(2, '0')}T23:59:59.999Z`;

    return isoString;
};
