import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { Divider, Checkbox, FormControlLabel, Select, MenuItem, Button, Grid, InputLabel, Collapse, IconButton, withStyles } from '@material-ui/core';
import { FilterList as FilterIcon } from '@material-ui/icons'; // Import filter icon
import EventCard from './EventCard';

// Custom styles for the IconButton
const StyledIconButton = withStyles({
  root: {
    fontSize: '1rem', // Make the font slightly smaller
    borderRadius: '4px', // Use rectangle instead of circle
    padding: '8px', // Add padding for better appearance
    color: '#000000'
  },
})(IconButton);

const UpcomingEvents = () => {
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); 
  const [notFull, setNotFull] = useState(false);
  const [notCharter, setNotCharter] = useState(false);
  const [location, setLocation] = useState('All');
  const [locations, setLocations] = useState([]);
  const [operator, setOperator] = useState('All');
  const [operators, setOperators] = useState([]);
  const bottomBoundaryRef = useRef(null);
  const [filtersOpen, setFiltersOpen] = useState(false);

  const fetchEvents = useCallback(async () => {
    setLoading(true);
    try {
      const offset = (page - 1) * 8;
      let url = `https://dive2scrape-api.azurewebsites.net/api/events/upcoming/8/${offset}`;
      if (notFull) url += '/notfull';
      if (notCharter) url += '/notcharter';
      if (location !== 'All') url += `/location/${location}`;
      if (operator !== 'All') url += `/operator/${operator}`;
      console.log(location)
      console.log(url)

      const response = await axios.get(url);
      if (page === 1) {
        setEvents(response.data);
      } else {
        setEvents(prevEvents => [...prevEvents, ...response.data]); // Append new events to existing ones
      }
      setLoading(false);
      setError(null);
    } catch (error) {
      console.error('Error fetching events:', error);
      setLoading(false);
      setError('Error fetching events. Please try again.');
    }
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get('https://dive2scrape-api.azurewebsites.net/api/locations');
        setLocations(['All', ...response.data]);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };
    fetchLocations();
  }, []);

  useEffect(() => {
    const fetchOperators = async () => {
      try {
        const response = await axios.get('https://dive2scrape-api.azurewebsites.net/api/operators');
        setOperators(['All', ...response.data]);
      } catch (error) {
        console.error('Error fetching operators:', error);
      }
    };
    fetchOperators();
  }, []);

  useEffect(() => {
    const handleIntersection = (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        setPage(prevPage => prevPage + 1);
      }
    };
  
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });
    const currentBottomBoundaryRef = bottomBoundaryRef.current;
    if (currentBottomBoundaryRef) {
      observer.observe(currentBottomBoundaryRef);
    }
    return () => {
      if (currentBottomBoundaryRef) {
        observer.unobserve(currentBottomBoundaryRef);
      }
    };
  }, [bottomBoundaryRef]);

  const handleNotFullChange = (event) => {
    setNotFull(event.target.checked);
  };

  const handleNotCharterChange = (event) => {
    setNotCharter(event.target.checked);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleOperatorChange = (event) => {
    setOperator(event.target.value);
  };

  const handleApplyFilters = () => {  
    setEvents([]);
    setPage(1);
  };

  const handleClearFilters = () => {
    setEvents([]);
    setNotFull(false);
    setNotCharter(false);
    setLocation('All');
    setOperator('All');
    setPage(1);
  };

  return (
    <div style={{ maxWidth: '900px', margin: '0 auto' }}>
      <StyledIconButton onClick={() => setFiltersOpen(!filtersOpen)}> {/* Change Button to StyledIconButton */}
        <FilterIcon /> 
        {filtersOpen ? 'Hide Filters' : 'Show Filters'}
      </StyledIconButton>
      <Collapse in={filtersOpen}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox checked={notFull} onChange={handleNotFullChange} />}
              label="Hide full"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox checked={notCharter} onChange={handleNotCharterChange} />}
              label="Hide charters"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <InputLabel>Locations</InputLabel>
            <Select value={location} onChange={handleLocationChange} fullWidth>
              {locations.map(loc => (
                <MenuItem key={loc} value={loc}>{loc}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <InputLabel>Operators</InputLabel>
            <Select value={operator} onChange={handleOperatorChange} fullWidth>
              {operators.map(op => (
                <MenuItem key={op} value={op}>{op}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleApplyFilters} style={{ marginRight: '10px' }}>Apply Filters</Button>
            <Button variant="contained" color="secondary" onClick={handleClearFilters}>Clear Filters</Button>
          </Grid>
        </Grid>
      </Collapse>

      {events.map((event, index) => (
        <div key={index}>
          <EventCard event={event} />
          {index < events.length - 1 && <Divider />}
        </div>
      ))}
      <div ref={bottomBoundaryRef} style={{ marginTop: '40px' }} />
      {loading && !error && <p>Loading, please wait...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default UpcomingEvents;
