import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import './EventCard.css'; // Import your CSS file

const EventCard = ({ event }) => {
  // Function to format date and time
  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    return date.toLocaleString(); // Format date and time according to user's locale
  };

  return (
    <Card className="event-card">
      <CardContent className="event-card-content">
        <Typography variant="h5" component="h2" className="event-card-title">
          {event.diveSite}
        </Typography>
        <Typography color="textSecondary">
          {formatDateTime(event.startTime)} {/* Format date and time */}
        </Typography>
        <Typography variant="body2" component="p" className="event-card-details">
          Operator: {event.organizer}
        </Typography>
        <Typography variant="body2" component="p" className="event-card-details">
          Location: {event.location}
        </Typography>
        {/* Display Depth only if it's non-empty */}
        {event.depth && (
          <Typography variant="body2" component="p" className="event-card-details">
            Depth: {event.depth}
          </Typography>
        )}
        {event.spaces && (
        <Typography variant="body2" component="p" className="event-card-details">
          Spaces: {event.spaces}
        </Typography>
        )}
        {event.price && (
        <Typography variant="body2" component="p" className="event-card-details">
          Price: {event.price}
        </Typography>
        )}
        {event.boat && (
        <Typography variant="body2" component="p" className="event-card-details">
          Boat: {event.boat}
        </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default EventCard;
