import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { getStartOfWeek, getEndOfWeek } from '../utils/dateUtils';
import { FilterList as FilterIcon } from '@material-ui/icons';
import { Checkbox, FormControlLabel, Select, MenuItem, Button, Grid, InputLabel, Collapse, IconButton, withStyles } from '@material-ui/core';
import './CalendarView.css'

const StyledIconButton = withStyles({
  root: {
    fontSize: '1rem',
    borderRadius: '4px',
    padding: '8px',
    color: '#000000'
  },
})(IconButton);

const CalendarView = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [formattedEvents, setFormattedEvents] = useState([]);
  const [viewType] = useState('timeGridWeek');
  const [notFull, setNotFull] = useState(false);
  const [notCharter, setNotCharter] = useState(false);
  const [location, setLocation] = useState('All');
  const [locations, setLocations] = useState([]);
  const [operator, setOperator] = useState('All');
  const [operators, setOperators] = useState([]);
  const [filtersOpen, setFiltersOpen] = useState(true);
  const [selectedWeekStart, setSelectedWeekStart] = useState(null);
  const [selectedWeekEnd, setSelectedWeekEnd] = useState(null);
  const [filtersCleared, setFiltersCleared] = useState(false);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get('https://dive2scrape-api.azurewebsites.net/api/locations');
        setLocations(['All', ...response.data]);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };
    fetchLocations();
  }, []);

  useEffect(() => {
    const fetchOperators = async () => {
      try {
        const response = await axios.get('https://dive2scrape-api.azurewebsites.net/api/operators');
        setOperators(['All', ...response.data]);
      } catch (error) {
        console.error('Error fetching operators:', error);
      }
    };
    fetchOperators();
  }, []);

  const handleDatesSet = (info) => {
    const startOfWeek = getStartOfWeek(info.view.activeStart);
    const endOfWeek = getEndOfWeek(info.view.activeEnd);
    setSelectedWeekStart(startOfWeek);
    setSelectedWeekEnd(endOfWeek);
    fetchEvents(startOfWeek, endOfWeek);
  };

  const fetchEvents = async (start, end) => {
    try {
      const eventsData = await fetchEventsForWeek(start, end);
      setFormattedEvents(formatEvents(eventsData));
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const eventContent = (arg) => {
    return (
      <Tooltip title={arg.event.title}>
        <div className="event-content">{arg.event.title}</div>
      </Tooltip>
    );
  };

  const splitPascalCase = (str) => {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2').trim();
  };

  const handleNotFullChange = (event) => {
    setNotFull(event.target.checked);
  };

  const handleNotCharterChange = (event) => {
    setNotCharter(event.target.checked);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleOperatorChange = (event) => {
    setOperator(event.target.value);
  };

  const handleApplyFilters = async () => {
    try {
      const response = await fetchEventsForWeek(selectedWeekStart, selectedWeekEnd);
      setFormattedEvents(formatEvents(response));
    } catch (error) {
      console.error('Error applying filters:', error);
    }
  };

  const handleClose = () => {
    setSelectedEvent(null);
  };

  const handleEventClick = (clickInfo) => {
    setSelectedEvent(clickInfo.event);
  };

  const handleClearFilters = async () => {
    setNotFull(false);
    setNotCharter(false);
    setLocation('All');
    setOperator('All');
    setFiltersCleared(true);
    try {
      const response = await fetchEventsForWeek(selectedWeekStart, selectedWeekEnd);
      setFormattedEvents(formatEvents(response));
    } catch (error) {
      console.error('Error clearing filters:', error);
    }
  };

  useEffect(() => {
    const fetchFilteredData = async () => {
      if (filtersCleared){
      try {
        const response = await fetchEventsForWeek(selectedWeekStart, selectedWeekEnd);
        setFormattedEvents(formatEvents(response));
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    }
    };
  
    // Call fetchData when filters are cleared
    if (filtersCleared) {
      fetchFilteredData();
      setFiltersCleared(false);
    }
  }, [filtersCleared]); // eslint-disable-line react-hooks/exhaustive-deps

  const formatEvents = (events) => {
    return events.map((event) => ({
      title: splitPascalCase(event.diveSite),
      operator: event.organizer,
      location: event.location,
      start: new Date(event.startTime),
      //end: new Date(event.endTime),
      id: event.uid,
      price: event.price,
      spaces: event.spaces
    }));
  };

  const fetchEventsForWeek = async (startTime, endTime) => {
    try {
        let url = `https://dive2scrape-api.azurewebsites.net/api/events/range/${startTime}/${endTime}`;
        if (notFull) url += '/notfull';
        if (notCharter) url += '/notcharter';
        if (location !== 'All') url += `/location/${location}`;
        if (operator !== 'All') url += `/operator/${operator}`;
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching events:", error);
        return [];
    }
  };

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    return date.toLocaleString(); // Format date and time according to user's locale
  };

  return (
    <div className="calendar-container" style={{ width: '100%' }}>
      <StyledIconButton onClick={() => setFiltersOpen(!filtersOpen)}>
        <FilterIcon /> 
        {filtersOpen ? 'Hide Filters' : 'Show Filters'}
      </StyledIconButton>
      <Collapse in={filtersOpen}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox checked={notFull} onChange={handleNotFullChange} />}
              label="Hide full"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox checked={notCharter} onChange={handleNotCharterChange} />}
              label="Hide charters"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <InputLabel>Locations</InputLabel>
            <Select value={location} onChange={handleLocationChange} fullWidth>
              {locations.map(loc => (
                <MenuItem key={loc} value={loc}>{loc}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <InputLabel>Operators</InputLabel>
            <Select value={operator} onChange={handleOperatorChange} fullWidth>
              {operators.map(op => (
                <MenuItem key={op} value={op}>{op}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleApplyFilters} style={{ marginRight: '10px' }}>Apply Filters</Button>
            <Button variant="contained" color="secondary" onClick={handleClearFilters}>Clear Filters</Button>
          </Grid>
        </Grid>
      </Collapse>
      <div className="calendar-wrapper" style={{ width: '100%' }}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin]}
          initialView={viewType}
          events={formattedEvents}
          eventContent={eventContent}
          eventClick={handleEventClick} 
          datesSet={handleDatesSet}
          height="auto"
          style={{ width: '100%' }}
        />
      </div>
      <Dialog open={selectedEvent !== null} onClose={handleClose}>
        <DialogTitle>{selectedEvent?.title}</DialogTitle>
        <DialogContent>
          <p>{selectedEvent?.diveSite?.toString()}</p>
          <p>Operator: {selectedEvent?.extendedProps?.operator?.toString()}</p>
          <p>Ropes off: {formatDateTime(selectedEvent?.start?.toString())}</p>
          <p>Location: {selectedEvent?.extendedProps?.location?.toString()}</p>
          <p>Spaces: {selectedEvent?.extendedProps?.spaces?.toString()}</p>
          {selectedEvent?.extendedProps?.price && (<p>Price: {selectedEvent?.extendedProps?.price?.toString()}</p>)}
          {selectedEvent?.extendedProps?.boat && (<p>Boat: {selectedEvent?.extendedProps?.boat?.toString()}</p>)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CalendarView;
