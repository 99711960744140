import React, { useState, useEffect } from "react";
import { Container, AppBar, Toolbar, Typography, BottomNavigation, BottomNavigationAction, Fab } from "@mui/material";
import CalendarViewIcon from '@mui/icons-material/CalendarToday';
import EventIcon from '@mui/icons-material/Event';
import CalendarView from "./components/CalendarView";
import UpcomingEvents from "./components/UpcomingEvents";
import { ArrowUpward } from '@mui/icons-material'; // Import ArrowUpward icon
import './App.css'; // Import your CSS file

function App() {
    const [events] = useState([]);
    const [view, setView] = useState('upcoming'); // State to track current view
    const [showScrollButton, setShowScrollButton] = useState(false); // State to track when to show the scroll button

    const handleChange = (event, newValue) => {
        setView(newValue);
    };

    // Function to handle scrolling and show/hide the scroll button
    const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        if (scrollTop > 200) {
            setShowScrollButton(true);
        } else {
            setShowScrollButton(false);
        }
    };

    // Effect to add scroll event listener when component mounts
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Function to scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div>
            <AppBar position="static" className="custom-app-bar">
                <Toolbar>
                    <Typography variant="h6" className="custom-app-bar-title">
                        Dive Scanner
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container maxWidth="md" className="container" style={{ marginTop: '64px' }}>
                <div className="content-container" style={{ width: '100%' }}>
                    {view === 'upcoming' && <UpcomingEvents events={events} />}
                    {view === 'calendar' && <CalendarView  events={events} />}
                </div>
            </Container>
            <BottomNavigation value={view} onChange={handleChange} className="bottom-navigation"
                sx={{
                    '& .Mui-selected': {
                    color: '#FFFFFF',
                    },
                    '& .MuiBottomNavigationAction-root': {
                    color: '#E0E0E0',
                    },
                    '& .MuiBottomNavigationAction-root:hover': {
                    color: '#BDBDBD',
                    },
                    '& .Mui-selected.MuiBottomNavigationAction-root:hover': {
                    color: '#E0E0E0',
                    },
                }}>
                    <BottomNavigationAction label="Upcoming Events" value="upcoming" icon={<EventIcon />} className="bottom-navigation-action" />
                    <BottomNavigationAction label="Calendar" value="calendar" icon={<CalendarViewIcon />} className="bottom-navigation-action" />
                </BottomNavigation>
            {/* Scroll to top button */}
            {showScrollButton && (
                <Fab color="primary" style={{ position: 'fixed', bottom: '80px', right: '20px', zIndex: '999' }} onClick={scrollToTop}>
                    <ArrowUpward />
                </Fab>
            )}
        </div>
    );
}

export default App;
